export default {

    'footer.signature': '©{year} EPIC by ',

    'header.user.signout': 'Esci',
    'header.user.signed': 'DNSSec abilitato',
    'header.search.placeholder': 'INFO nome dominio',

    'login.error.wrongCredentials': 'Credenziali errate',
    'login.form.message.username.required': 'Inserisci un nome utente!',
    'login.form.message.username.placeholder': 'Nome utente',
    'login.form.message.username.label': 'Nome utente',
    'login.form.message.password.required': 'Inserisci una password!',
    'login.form.message.password.placeholder': 'Password',
    'login.form.message.password.label': 'Password',

    'domain.detail.contacts': 'Contatti',
    'domain.detail.domain': 'Dominio',
    'domain.detail.registrant': 'Registrante',
    'domain.detail.adminContact': 'Contatto Amministratore',
    'domain.detail.techsContact': 'Contatti Tecnici',
    'domain.detail.dsRecords': 'Record DS',
    'domain.detail.registrar': 'Registrar',
    'detail.dsRecord.toValidate': 'DS Record in validazione',
    'domain.detail.signed': 'Signed',
    'domain.detail.signed.yes': 'si',
    'domain.detail.signed.no': 'no',

    'contact.detail.contact': 'Contatto',
    'contact.detail.organization': 'Organizzazione',
    'contact.detail.name': 'Nome',
    'contact.detail.consent4pub': 'Consenso pubblicazione',
    'contact.detail.form.contactType': 'Tipologia contatto',
    'contact.detail.form.consent': 'si',
    'contact.detail.form.notConsent': 'no',
    'contact.detail.form.organization.error.message.orgequalsname': 'EntityType = 1, l\'organizzazione deve essere uguale al nome',
    'contact.detail.form.id.placeholder': 'ID del nuovo contatto (almeno 3 caratteri)',
    'contact.detail.form.id.error.message.idValid': 'ID contatto valido',
    'contact.detail.form.id.error.message.idNotValid': 'Contatto già esistente',
    'contact.detail.form.id.error.message.idNotPresent': 'Contatto non esistente',
    'contact.detail.form.id.error.message.checkIderror': 'Impossibile verificare contatto ({error})',
    'contact.detail.form.id.error.message.notValidRegistrant': 'Id registrante non valido',
    'contact.detail.form.id.error.message.idLess3chr': 'Inserire un ID contatto composto da almeno 3 caratteri',
    'contact.detail.value': 'Acconsente',
    'contact.detail.contacts': 'Contatti',
    'contact.detail.address': 'Indirizzo',
    'contact.detail.registrant': 'Registrante',
    'detail.registrant.nationalityCode': 'Nazionalità',
    'detail.registrant.entityType': 'Tipologia',
    'detail.registrant.regCode': 'Codice Registrante',
    'detail.registrant.schoolCode': 'Codice Scuola',
    'detail.registrant.gov.ipaCode': 'Codice IPA',
    'detail.registrant.gov.uoCode': 'Codice Unità Operativa',

    'detail.domain.hosts': 'Hosts',
    'detail.domain.nameserver': 'Nameserver',
    'detail.domain.nameserver.title': 'Nameserver',
    'detail.domain.nameserver.ipv4': 'Indirizzo IP v4',
    'detail.domain.nameserver.ipv6': 'Indirizzo IP v6',
    'detail.domain.nameserver.toValidate': 'Name Server in validazione',
    'detail.domain.nameserver.actual': 'Name Sever attuali',

    'detail.domain.name': 'Nome',
    'detail.domain.punycodeDomain': 'Dominio IDNA',
    'detail.domain.status': 'Stato',
    'detail.domain.crDate': 'Creazione',
    'detail.domain.exDate': 'Scadenza',
    'detail.domain.upDate': 'Ultimo aggiornamento',
    'detail.contact.organization': 'Organizzazione',
    'detail.contact.address': 'Inidirizzo',
    'detail.contact.address.city': 'Città',
    'detail.contact.address.placeholder.city': 'Nome della città',
    'detail.contact.address.streets': 'Indirizzo',
    'detail.contact.address.postalCode': 'Codice postale',
    'detail.contact.address.placeholder.postalCode': 'Codice di avviamento postale',
    'detail.contact.address.state': 'Provincia',
    'detail.contact.address.placeholder.state': 'Codice provincia (es. PI)',
    'detail.contact.address.countryCode': 'Paese',
    'detail.contact.nationality': 'Nazionalità',
    'detail.contact.phone': 'Telefono',
    'detail.contact.fax': 'Fax',
    'detail.contact.email': 'E-mail',
    'detail.contact.crDate': 'Creazione',
    'detail.contact.upDate': 'Ultimo aggiornamento',
    'detail.contact.name': 'Nome',
    'detail.contact.id': 'Id',
    'detail.contact.statuses': 'Stato',

    'detail.dsRecord.keyTag': 'Key Tag',
    'detail.dsRecord.alg': 'Alg',
    'detail.dsRecord.digestType': 'Digest Type',
    'detail.dsRecord.digest': 'Digest',
    'detail.dsRecord.actual': 'Attuali',

    'contact.detail.message.emptyDetail': 'Nessun dettaglio',
    'contact.detail.label.id': 'Id',
    'contact.detail.label.name': 'Nome',
    'contact.detail.label.organization': 'Organizzazione',
    'contact.detail.label.telephone': 'Tel.',
    'contact.detail.label.email': 'e-mail',
    'contact.detail.label.fax': 'fax.',
    'contact.detail.label.admin': 'Admin',

    'contact.action.modify': 'Modifica',
    'contact.action.delete': 'Elimina',

    'contact.action.delete.title': 'Rimozione del contatto \'{name}\'?',
    'contact.action.delete.content': 'Attenzione stai richiedendo l\'operazione di eliminazione contatto. Sei sicuro di voler procedere ?',
    'contact.action.delete.okText': 'Si',
    'contact.action.delete.cancelText': 'No',

    'contact.detail.form.statuses': 'Stato contatto',
    'contact.detail.form.status': 'Stato',
    'contact.detail.form.statuses.message': 'Seleziona almeno un stato',
    'contact.detail.form.statuses.placeholder': 'Aggiungi gli stati',
    'contact.detail.form.name': 'Nome',
    'contact.detail.form.address': 'Indirizzo',
    'contact.detail.form.address.streets': 'Indirizzo',
    'contact.detail.form.address.streets.message': 'Specifica almeno un indirizzo',
    'contact.detail.form.address.streets.street': 'Indirizzo',
    'contact.detail.form.address.streets.street.placeholder': 'Nome della via/strada/Piazza',
    'contact.detail.form.address.streets.street.message': 'Specifica un indirizzo o eliminalo',
    'contact.detail.form.address.city': 'Città',
    'contact.detail.form.address.city.message': 'Specifica una città',
    'contact.detail.form.address.postalCode': 'Codice postale',
    'contact.detail.form.address.postalCode.message': 'Specifica un codice di avviamento postale',
    'contact.detail.form.address.countryCode': 'Paese',
    'contact.detail.form.address.countryCode.message': 'Specifica un codice nazione',
    // 'detail.contact.address.placeholder.countries': 'Codice nazione (es. IT), se necessario ricercarlo per nome nazione',
    'detail.contact.address.placeholder.countryCode': 'Codice nazione da ricercare tramite nome nazione',
    'contact.detail.form.address.state': 'Provincia',
    'contact.detail.form.address.state.message': 'Specifica una provincia',
    'contact.detail.form.address.state.StateLen2': 'Provincia italiana, specifica il codice provincia di 2 lettere (es. PI)',
    'contact.detail.form.address.postalCode.italianLength': 'CAP di un comune Italiano, inserire 5 caratteri',
    'contact.detail.form.address.postalCode.notItalianLength': 'Il CAP non può essere superiore ai 16 caratteri',
    'contact.detail.form.contactId': 'Id',

    'contact.detail.form.registrant': 'Registrante',
    'contact.detail.form.registrant.nationalityCode': 'Nazionalità',
    'contact.detail.form.registrant.nationalityCode.message': 'Specifica una nazionalità',
    'contact.detail.form.registrant.entityType': 'Entità',
    'contact.detail.form.registrant.entityType.message': 'Specifica la tipologia del contatto',
    'contact.detail.form.registrant.regCode': 'Codice registrante',
    'contact.detail.form.registrant.regCode.message': 'Definisci un codice registrante',
    'contact.detail.form.registrant.regCode.len': 'Il codice deve essere di 16 caratteri',
    'contact.detail.form.registrant.regCode.pattern': 'la sequenza dei caratteri non risulta corretta',
    'contact.detail.form.registrant.schoolCode': 'Codice scuola',
    'contact.detail.form.registrant.ipaCode': 'Codice IPA',
    'contact.detail.form.registrant.ipaCode.message': 'Definisci un Codice IPA',
    'contact.detail.form.registrant.uoCode': 'Codice Unità Organizzativa',
    'contact.detail.form.registrant.uoCode.message': 'Inserisci il codice di una Unità Operativa',
    'contact.detail.form.registrant.schoolCode.message': 'Definisci un codice scuola',
    'contact.detail.form.registrant.schoolCode.len': 'Il codice deve essere di 9 caratteri',
    'contact.detail.form.registrant.schoolCode.pattern': 'la sequenza dei caratteri non risulta corretta',
    'contact.detail.form.checkOrganization.message': 'Il valore del campo Organizzazione deve conincidere con quello del campo Name',
    'contact.detail.form.registrant.placeholder.countryCode': 'Codice nazione da ricercare tramite nome nazione',
    'contact.detail.form.registrant.piva': 'Partita Iva o Codice fiscale numerico',
    'contact.detail.form.registrant.fiscalCode': 'Codice fiscale',
    'contact.detail.form.registrant.vat': 'Partita Iva',
    'contact.detail.form.registrant.na': 'n.a.',
    'contact.detail.form.name.message': 'Specifica un nominativo',
    'contact.detail.form.name.placeholder': 'Nominativo del nuovo contatto (es. Mario Rossi)',
    'contact.detail.form.organization': 'Organizzazione',
    'contact.detail.form.organization.message': 'Organizzazione non valida',
    'contact.detail.form.organization.placeholder': 'Nome organizzazione del nuovo contatto',
    'contact.detail.form.telephone': 'Telefono',
    'contact.detail.form.telephone.invalid.message': 'Il formato del numero di telefono e` incorretto',

    'contact.detail.form.telephone.message': 'Inserici un numero di telefono valido',
    'contact.detail.form.telephone.placeholder': 'Numero di telefono valido (es. +39.05050000)',
    'contact.detail.form.fax': 'Fax',
    'contact.detail.form.fax.placeholder': 'Numero di fax valido (es. +39.05050001)',
    'contact.detail.form.fax.message': 'Specifica un numero di fax',
    'contact.detail.form.email': 'Email',
    'contact.detail.form.pec': 'PEC',
    'contact.detail.form.email.invalid.message': 'Formato della E-Mail non valido',
    'contact.detail.form.email.placeholder': 'Email in formato valido',
    'contact.detail.form.email.message': 'Specifica una email',

    'contact.detail.form.pec.invalid.message': 'Formato della PEC non valido',
    'contact.detail.form.pec.placeholder': 'PEC in formato valido',
    'contact.detail.form.pec.message': 'Specifica una PEC',

    'contact.detail.form.consentForPublish': 'Consenso pubblicazione',
    'contact.detail.form.save': 'Salva',
    'contact.detail.form.clear': 'Annulla',
    'contact.detail.update.form.title': 'Modifica Contatto',

    'contact.list.title': 'Lista contatti',
    'contact.list.form.nameOrOrg': 'Nome o organizzazione',
    'contact.list.form.name': 'Nome',
    'contact.list.form.contactType': 'Tipo contatto',
    'contact.list.form.org': 'Organizzazione',
    'contact.list.form.contactId': 'Id contatto',
    'contact.list.form.email': 'E-mail',
    'contact.list.form.city': 'Città',
    'contact.list.form.search': 'Cerca',
    'contact.list.form.clear': 'Pulisci',
    'contact.list.form.expand': 'Espandi',
    'contact.list.form.removeFilter': 'Rimuovi filtri',
    'contact.list.form.filterResult': 'Filtra risultati',
    'contact.list.form.filter': 'Filtra',
    'contact.list.form.message.empty': 'Il campo non può essere vuoto',
    'contact.list.form.message.all': 'tutti',
    'contact.list.form.message.registrant': 'registrante',
    'contact.list.form.message.contact': 'contatto',

    'contact.list.header.contactId': 'Id',
    'contact.list.header.name': 'Nome',
    'contact.list.header.organization': 'Organizzazione',
    'contact.list.header.email': 'E-mail',
    'contact.list.header.telephone': 'Telefono',
    'contact.list.header.address': 'Indirizzo',
    'contact.list.header.contactType': 'Tipo',
    'contact.list.header.state': 'Prov.',
    'contact.list.header.city': 'Città',
    'contact.list.header.country': 'Paese',
    'contact.list.message.noResults': 'Nessun risultato',

    'domain.list.title': 'Lista domini',
    'domain.list.form.domain': 'Dominio',
    'domain.list.form.registrantId': 'ID Registrante',
    'domain.list.form.adminId': 'ID Ammnistratore',
    'domain.list.form.host': 'Host',
    'domain.list.form.status': 'Stato',
    'domain.list.form.removeFilter': 'Rimuovi filtri',
    'domain.list.form.filterResult': 'Filtra risultati',
    'domain.list.form.filter': 'Filtra',
    'domain.list.form.message.empty': 'Il campo non può essere vuoto',

    'domain.list.header.domain': 'Dominio',
    'domain.list.header.status': 'Stati',
    'domain.list.header.expire': 'Data di scadenza',
    'domain.list.header.created': 'Data di creazione',
    'domain.list.header.hosts': 'Hosts associati',
    'domain.list.message.noResults': 'Nessun risultato',

    'filterbar.activefilter': 'Filtri attivi',
    'filterbar.removefilters': 'Rimuovi fitri',
    'filterbar.contactType.contact': 'contatto',
    'filterbar.contactType.registrant': 'registrante',

    'contact.info.form.title': 'Info Contatto',
    'contact.info.form.placeholder': 'Id Contatto (es: CCTL1)',
    'contact.info.inputContact.label': 'Contatto',
    'contact.info.inputContact.extra': 'Inserisci un contatto',
    'contact.info.input.field.contact.message': 'È necessario inserire un contatto',
    'contact.info.submit.button': 'INFO',
    'contact.info.form.button.reset': 'Annulla',

    'contact.new.form.title': 'Nuovo contatto',



    'domain.detail.message.emptyDetail': 'Nessun dettaglio',
    'domain.detail.label.name': 'Nome',
    'domain.detail.label.registrant': 'Registrante',

    'domain.detail.label.status': 'Stato',
    'domain.detail.label.techsid': 'TechsID',
    'domain.detail.label.admin': 'Admin',

    'domain.info.form.inputDomain.label': 'Dominio',
    'domain.info.form.title': 'Info dominio',
    'domain.info.form.button': 'INFO',
    'domain.info.form.inputDomain.extra': 'Nome a dominio (es. miodominio.it)',
    'domain.info.form.inputDomain.rule.message': 'È necessario inserire un nome a dominio',
    'domain.info.form.inputAuhinfo.rule.message': 'È necessario inseriro un codice authinfo',
    'domain.info.form.authinfo.label': 'AuthInfo',
    'domain.info.form.authinfo.placeholder': 'Codice AuthInfo opzionale',
    'domain.info.form.authinfo.rule.message': 'Inserisci un AuthInfo opzionale',
    'domain.info.form.button.info': 'INFO',
    'domain.info.form.button.reset': 'Annulla',
    'domain.info.delete.title': 'Elimina dominio',
    'domain.info.delete.content': 'Sicuri di voler eliminare il dominio {name} ?',
    'domain.info.delete.okText': 'Si',
    'domain.info.delete.cancelText': 'No',
    'domain.info.delete.success': 'Richiesta di cancellazione per il dominio {name} eseguita con successo',
    'domain.info.delete.success.title': 'Richiesta di cancellazione',
    'domain.info.delete.failure': 'Richiesta di cancellazione per il dominio {name} non eseguita: {response}',
    'domain.info.delete.failure.title': 'Errore nella richiesta di cancellazione',
    'domain.info.restore.title': 'Recupera il dominio',
    'domain.info.restore.content': 'Recuperare domnio {name} ? ',
    'domain.info.restore.success': 'Dominio {name} recuperato con successo',
    'domain.info.restore.success.title': 'Recupero dominio',
    'domain.info.restore.failure': 'Il recupero del dominio {name} non è stato eseguito: {response}',
    'domain.info.restore.failure.title': 'Errore nel recupero dominio',

    'domain.info.delete.transfer.title': 'Cancella trasferimento dominio',
    'domain.info.delete.transfer.content': 'Sei sicuro di voler cancellare la richiesta di trasferimento per il dominio {name} ?',

    'domain.update.host.form.title': 'Modifica dei nameserver',
    'domain.update.contact.form.title': 'Modifica dei contatti (amministratore, tecnici)',
    'domain.update.authinfo.form.title': 'Modifica dell\'authinfo',
    'domain.update.registrant.form.title': 'Modifica del registrante',
    'domain.update.status.form.title': 'Modifica dello stato',
    'domain.update.status.form.new': 'Stati aggiunti/rimossi',


    'domain.update.authinfo.new': 'Nuovo authinfo',
    'authInfo.equals': 'Il valore authinfo inserito coincide con il precedente',


    'domain.update.form.currentData': 'Dati attuali',
    'domain.update.form.modify': 'Modifica',
    'domain.update.form.authinfo': 'Authinfo',
    'domain.update.form.registrant': 'Registrante',
    'domain.update.form.registrant.actual': 'Attuale registrante',
    'domain.update.form.registrant.new': 'Nuovo registrante',
    'domain.update.form.registrant.modify': 'Nuovo registrante',

    'domain.update.form.registrantId': 'Id registrante',

    'domain.info.action.menu.showAuthInfo': 'Visualizza authinfo',
    'domain.info.action.menu.edit': 'Modifica',
    'domain.info.action.menu.editSimple': 'Contatti',
    'domain.info.action.menu.editHost': 'Nameserver',
    'domain.info.action.menu.editAuthinfo': 'Authinfo',
    'domain.info.action.menu.editStatus': 'Stato',
    'domain.info.action.menu.editRegistrant': 'Registrante',

    'domain.info.request.transfer.title': 'Richiesta di trasferimento',
    'domain.info.request.transfer.content': 'Richiedi trasferimento per il dominio {name}.',
    'domain.approve.transfer.title': 'Approvazione di trasferimento',
    'domain.approve.transfer.content': 'Approva il trasferimento del dominio {name} presso altro registrar',
    'domain.reject.transfer.title': 'Respingi di trasferimento',
    'domain.reject.transfer.content': 'Rifiuta il trasferimento del dominio {name}',

    'domain.transfer.status.form.title': 'Trasferimento dominio - stato',
    'domain.transfer.status.notInTransfer': 'Nessun trasferimento in corso',
    'domain.transfer.status.header.from': 'Provenienza',
    'domain.transfer.status.header.to': 'Destinazione',
    'domain.transfer.status.header.reqDate': 'Data richiesta',
    'domain.transfer.status.header.acptDate': 'Data accettazione',
    'domain.transfer.accept': 'Accetta',
    'domain.transfer.reject': 'Rifiuta',
    'domain.transfer.cancelTransfer': 'Cancella trasferimento',
    'domain.transfer.submit': 'Trasferisci',

    'domain.info.action.menu.transfer': 'Trasferisci',
    'domain.info.action.menu.transferDelete': 'Elimina',
    'domain.info.action.menu.transferManage': 'Gestisci',
    'domain.info.action.menu.transferApprove': 'Approva',
    'domain.info.action.menu.transferReject': 'Rifiuta',
    'domain.info.action.menu.transferStatus': 'Info ultimo trasferimento',
    'domain.info.action.menu.transferRequest': 'Richiedi',
    'domain.info.action.menu.transferTradeRequest': 'Transfer Trade',
    'modal.status.title': 'Stato di trasferimento',
    'domain.info.action.menu.title': 'Azioni',
    'modal.authinfo.title': 'Codice authinfo',
    'domain.info.action.menu.delete': 'Elimina',
    'domain.info.action.menu.restore': 'Recupera',

    'searchBox.label.search': 'Ricerca',
    'searchBox.placeholder': 'Inserisci termine da ricercare',

    'domain.new.wizard.title': 'Nuovo Dominio',

    'domain.transfer.trade.form.title': 'Trasferimento dominio (semplice - trade)',
    'domain.tansfer.trade': 'Trasferisci',
    'trade': 'Trade',
    'domain.new.Wizard.searchContactByName':'Cerca per Nome',
    'domain.new.Wizard.contactNameLength':'Nome Contatto deve contenere almento 3 caratteri',
    'domain.new.Wizard.contactIdLenght':'ID Contatto deve contenere almento 3 caratteri',
    'domain.new.Wizard.emptyField':'Campo Vuoto. Deve essere specificato',
    'domain.new.Wizard.authInfoLen':"Lunghezza authinfo non valida:{length},deve essere tra {min} e {max}",
    'domain.new.Wizard.contactNotfound':'Contatto inesistente',
    'digestValueError': 'Lunghezza del digest non valida: {length},  deve essere {required}',

    'domains': 'Domini',
    'contacts': 'Contatti',
    'domain': 'Dominio',
    'search': 'Ricerca',
    'info': 'Info',
    'contact': 'Contatto',
    'list': 'Lista',

    'Contact': 'Contatto',
    'Registrant': 'Registrante',
    'RegistrantEdu': 'Registrante Edu',
    'RegistrantGov': 'Registrante Gov',

    /* EPP MESSAGES */
    'eppMessages': 'Messaggi EPP',
    'eppMessage.list.title': 'Lista Messaggi EPP',
    'eppMessage.pollReq.disabled': 'Polling non attivo',
    'eppMessages.filterkey.message': 'Messaggio',
    'eppMessages.filterkey.domain': 'Dominio',
    'eppMessages.filterkey.read': 'Letto',
    'eppMessages.filterkey.type': 'Tipo',
    'eppMessages.filterkey.date': 'Data',
    /* ***************** */



    // Breadcrumb
    'root.domain': 'Dominio',
    'root.domain.new': 'Nuovo Dominio',
    'root.domain.info': 'Info',
    'root.domain.check': 'Controllo',
    'root.contact': 'Contatto',
    'root.contact.info': 'Info',
    'root.contact.update': 'Modifica',


    'eppOperations': 'Operazioni EPP ',
    'check': 'Check',

    'domain.check.title': 'Check dominio',
    'domain.check.domainAvailable': 'Dominio disponibile',
    'domain.check.domainNotAvailable': 'Dominio non disponibile',
    'domain.check.inputDomain.label': 'Dominio',
    'domain.check.inputDomain.placeHolder': 'Nome a dominio (es. miodominio.it)',
    'domain.check.inputDomain.extra': 'Inserisci dominio',
    'domain.check.input.field.domain.message': 'Inserisci un dominio da verificare',


    //EntityTypeSelect
    'entityTypeSelect.1': '1 - Persone fisiche',
    'entityTypeSelect.2': '2 - Società/Imprese individuali',
    'entityTypeSelect.3': '3 - Liberi professionisti/Ordini professionali',
    'entityTypeSelect.4': '4 - Enti no profit',
    'entityTypeSelect.5': '5 - Enti pubblici',
    'entityTypeSelect.6': '6 - Altri soggetti',
    'entityTypeSelect.7': '7 - Soggetti stranieri escluso persone fisiche',

    //ContactTypeSelect
    'contactTypeSelect.contact': 'contatto',
    'contactTypeSelect.registrant': 'registrante',

    //Contact-Check
    'contact.check.title': 'Check contatto',
    'contact.check.submit.button': 'CHECK',
    'contact.check.form.button.reset': 'Annulla',
    'contact.check.contactNotAvailable': 'Contatto non disponibile',
    'contact.check.contactAvailable': 'Contatto disponibile',
    'contact.check.inputContact.placeHolder': 'Id Contatto (es: CCTL1)',
    'contact.check.input.field.contact.message': 'È necessario inserire un contatto',

    'contact.update.error': 'Aggiornamento del contatto fallito : {errorMessage}',
    'contact.create.error': 'Creazione del nuovo contatto fallita : {errorMessage}',
    'domain.action.error': 'Operazione fallita : {errorMessage}',
    'domain.action.success': 'Successo : {successMessage}',

    // Configuration Wizard
    prev: 'Precedente',
    next: 'Successivo',
    done: 'Fatto',

    eppServerLabel: 'Server EPP',
    eppServerDeletedLabel: 'Server EPP Deleted',
    eppLanguage: 'lingua',
    eppLanguageMessage:'Seleziona una lingua',
    enabled: 'Abilitato',
    disabled: 'Disattivato',
    eppServerMessage: 'Inserisci o seleziona una URL del Server Epp. La url non deve contenere spazi e deve essere https://<server>.it',
    eppServerPlaceholder: '',
    eppServeDeletedMessage: 'Inserisci o seleziona una URL del Server Epp Deleted. La url non deve contenere spazi e deve essere https://<server>.it',


    userNameLabel: 'Utente Epp',
    userNameMessage: 'Utente Epp deve essere specificato: non deve contenere spazi, maiuscolo e deve terminare con -REG',
    notCorrectLength: 'La lunghezza deve essere compresa tra 4 e 16 caratteri',
    userNamePlaceholder: 'Utente Registrar Epp',

    eppPasswordLabel: 'Password Utente Epp',
    eppPasswordMessage: 'Password dell\'utente Epp deve essere specificata e non deve contenere spazi',
    eppPasswordPlaceholder: 'Password Registrar Epp',

    confirmEppPasswordLabel: 'Conferma Passord',
    confirmEppPasswordMessage: 'La password di conferma deve coincidere con il campo password',
    confirmEppPasswordMessage2: 'La password di conferma deve essere specificata e non deve contenere spazi',
    confirmEppPasswordPlaceholder: 'Conferma password utente Epp',

    adminNameLabel: 'Utente Amministratore',
    adminNameMessage: 'Utente Amministratore deve essere specificato e non deve contenere spazi',
    adminNamePlaceholder: 'Utente Amministratore del client EPIC',

    fullNameLabel: 'Nome Completo',
    fullNameMessage: 'Nome Completo dell\'Utente Amministratore',
    fullNamePlaceholder: 'Nome Completo deve essere specificato',


    adminEmailLabel: 'Email',
    adminEmailMessage: 'Email dell\'utente deve essere specificata',
    adminEmailPlaceholder: 'Email Utente Anministratore',

    adminPasswordLabel: 'Password',
    adminPasswordMessage: 'La password dell\'Utente Amministratore deve essere specificata. La lunghezza deve essere compresa tra 6 e 32 caratteri',
    adminPasswordPlaceholder: 'Password Utente Amministratore',

    confirmAdminPasswordLabel: 'Conferma Password',
    confirmAdminPasswordMessage: 'Password di conferma deve essere specificata e deve coincidere con quella del campo password',
    confirmAdminPasswordPlaceholder: 'Conferma della password dell\'Utente Amministratore',

    'userConfiguration.info.message': 'Configurazione Utente',
    'userConfiguration.info.description.': 'I dati dell\'utente saranno memorizzati',
    'userConfiguration.error.message': 'Configurazione Utente',
    'userConfiguration.error.description': 'Un errore si è verificato nel salvataggio dei dati dell\'utente. Utente già configurato',

    'userConfiguration.success.message': 'Configurazione Utente',
    'userConfiguration.success.description': 'Dati dell\'utente memorizzati',

    'serverConfiguration.info.message': 'Configurazione Server Epp',
    'serverConfiguration.info.description': 'La configurazione del server sarà memorizzata',

    'serverConfiguration.success.message': 'Configurazione Server Epp',
    'serverConfiguration.success.description': 'La configurazione del server memorizzata con successo',

    'serverConfiguration.error.message': 'Configurazione Server Epp',
    'serverConfiguration.error.description': 'Si è verificato un errore nel salvataggio della configurazione del server',

    'domainCreation.domain.dnssecTitle': 'DNSSec',
    'domainCreation.domain.dnssecDescription': 'Configurazione Record DS',

    'connectionOrServer.error.message':'Wizard Configurazione ',
    'connectionOrServer.error.description': 'Errore connessione con il server',



    /*** Alter **/
    'ContainsSpace' : 'Contiene spazi',

    //--- End Configuration Wizard --
    // Splash
    loadingConfiguration: 'Verifica Configurazione',
    errorOccurred: 'Si è verificato un errore nella comunicazione con il server.',
    //--- End Splash
    // New Domain Wizard
    "domainCreation.domain.title": "Dominio",
    "domainCreation.domain.description": "Nuovo dominio da registrare",
    "domainCreation.contacts.title": "Contatti",
    "domainCreation.contacts.description": "Contatti associati al dominio",
    "domainCreation.dns.title": "Domain Server",
    "domainCreation.dns.description": "Configurazione dei dns",


    "domain.update.host.step1.title": "Modifica Nameserver",
    'domain.update.host.dnsHold': 'Nameserver in validazione',
    "domain.update.host.step1.description": "Seleziona per rimuovere",
    "domain.update.host.step1.table.title": "Nameserver associati",
    "domain.update.host.step2.title": "Inserisci Nameserver",
    "domain.update.host.step2.description": "Associa agli esistenti",
    "domain.update.host.step3.title": "Modifica RecordDS",
    "domain.update.host.step3.description": "Seleziona per rimuovere",
    "domain.update.host.step3.table.actual": "Record DS da rimuovere",
    "domain.update.host.step4.title": "Inserisci RecordDS",
    "domain.update.host.step4.description": "Aggiungi agli esistenti",
    "domain.update.host.step4.table.actual": "DSRecord da modificare",
    "domain.update.host.summary.title": "Riepilogo",
    "domain.update.host.summary.description": "Nameserver rimossi e aggiunti",
    "domain.update.host.summary.table.host.preview": "Anteprima",
    "domain.update.host.summary.table.host.edited": "Host modificati",
    "domain.update.host.summary.table.host.edited.deleted": "Rimossi",
    "domain.update.host.summary.table.host.edited.added": "Aggiunti",
    "domain.update.host.summary.table.dsRecords.edited": "Record DS modificati",
    "domain.update.host.summary.table.dsRecords.added": "Aggiunti",
    "domain.update.host.summary.table.dsRecords.deleted": "Rimossi",

    "domain.update.simple.step1.title": "Contatti da modificare",
    "domain.update.simple.step1.description": "I contatti selezionati verrano rimossi",
    "domain.update.simple.step1.table.title": "Contatti associati",
    "domain.update.simple.step2.title": "Aggiungi contatto",
    "domain.update.simple.step2.description": "I contatti inseriti verrano aggiunti a quelli già associati",
    "domain.update.simple.step3.title": "Riepilogo",
    "domain.update.simple.step3.description": "Contatti rimossi e aggiunti",
    "domain.update.simple.step3.table.contact.preview": "Anteprima",
    "domain.update.simple.step3.table.contact.edited": "Contatti modificati",
    "domain.update.simple.step3.table.contact.edited.deleted": "Rimossi",
    "domain.update.simple.step3.table.contact.edited.added": "Aggiunti",


    "domain.update.authinfo": "Aggiorna Authinfo",
    "domain.update.status": "Aggiorna stato",
    "domain.update.registrant": "Aggiorna registrante",
    "contact.update.status": "Aggiorna stato",


    new: 'Nuovo',
    'domain.new': 'Nuovo Dominio',
    domainNameLabel: 'Nome Dominio',
    domainNamePlaceholder: 'Nome Dominio da Registrare',
    domainNameMessage: 'Nome del Dominio deve essere specificato e non deve essere stato già registrato',
    // Validatore
    domainNameTooShort: "Nome del dominio troppo corto",
    domainBelowMinTokens: "Nome del dominio troppo corto o non valido",
    domainTokenTooShort: "Nome del dominio troppo corto o non valido",
    domainInvalidTLD: 'Creazione solo di domini .it',
    domainTokenStartsWithDash: "Nome dominio inizia con trattino",
    domainTokenEndsWithDash: "Nome dominio finisce con trattino",
    domainTokenInvalid: 'Carattere non valido',

    emptyDNSName: 'Campo non nullo. DNS deve essere speficicato od eliminato',
    invalidDNSName: 'DNS non valido',
    invalidDomainName: 'Nome a dominio non valido',
    invalidContactId: 'Id contatto non valido',
    requiredContactId: 'Specifica un id contatto',
    invalidTelphoneNumber: 'Formato del numero di telefono non valido',
    countryCodeTooLong: "Codice nazione troppo lungo, deve contenere 2 caratteri",
    countryCodeTooShort: "Codice nazione troppo corto, deve contenere 2 caratteri",
    invalidCountryCode: "Codice nazione non valido",
    requiredCountryCode: "Specifica un codice nazione",
    duplicateDNS: 'Valore DNS già presente',

    authInfoLabel: 'AuthInfo',
    govValidationCodeLabel: 'Codice Validazione GOV',
    govValidationCodePlaceholder: 'Codice Validazione fornita all\'amministrazione nella fase di validazione',
    authInfoPlaceholder: 'Codice Authinfo associato al Dominio',
    authInfoMessage: 'Codice Authinfo deve essere specificato. La lunghezza deve essere compresa tra 4 e 32',
    generateUUID: 'Genera AuthInfo',

    registrantContactIdLabel: 'Contact ID Registrante',
    registrantContactIdMessage: 'Campo Obbligatorio',
    registrantContactIdPlaceholder: 'Identificatore del Contatto del Registrante',

    adminContactIdLabel: 'Contact ID Amministratore',
    adminContactIdMessage: 'Campo Obbligatorio',
    adminContactIdPlaceholder: "Identificatore del Contatto Admin",

    techContactIdLabel: 'Contact ID Tecnici',
    techContactIdMessage: 'Campo non nullo. Deve essere specificato od eliminato',
    techContactIdPlaceholder: 'Identificatore del Contatto Tecnico',
    AlreadyDeclared: 'Valore già presente',
    addTechId: 'Aggiungi Contatto Tecnico',

    insertValidDomainOrIP: "Inserisci ",
    invalidIPV4: 'Indirizzo IPv4 non valido',
    invalidIPV6: 'Indirizzo IPv6 non valido',
    insertValidIPv4: 'Inserisci indirizzo IPv4 valido',
    insertValidIPv6: 'Inserisci indirizzo IPv6 valido',
    addDNS: 'Aggiungi DNS',
    nameserver: 'Name Server',
    notEmptyField: 'Campo Obbligatorio',

    'server.500': 'Problemi di connessione con il server.',


    keyTag: 'KeyTag',
    keyTagMessage: 'Campo Obbligatorio: valore..',
    keyTagPlaceholder: "Inserisci Key Tag",
    keyTagRangeError:'Campo Obbligatorio. Valori compresi tra 0 e 65535',
    algorithm: 'Algoritmo',
    algorithmMessage: 'Seleziona valore tra quelli disponibili',
    algorithmPlaceholder: 'Seleziona algoritmo',

    digestType: 'Tipo Digest',
    digestTypeMessage: 'Scegli valore tra quelli disponibili',
    digestTypePlaceholder: 'Selezione tipo digest',

    digest: 'Digest',
    digestMessage: 'Campo non nullo',
    digestPlaceholder: 'Valore digest',

    addDSRecord: 'Aggiungi Record DS',

    "domainCreation.result.success.message": "Creazione Dominio",
    "domainCreation.result.success.description": "dominio creato con successo",
    "domainCreation.result.error.message": "Creazione Dominio",
    "domainCreation.result.error.description": "creazione dominio fallita",
    // -- End New Domain Wizard


    // Comfigurazione Menu Admin
    'menu.admin': 'Configurazione',
    'admin.polling.title': 'Configurazione Polling',
    "admin.email.title": 'Configurazione Server Email',

    "admin.epp.title": 'Configurazione Server EPP',
    "admin.password.title": 'Configurazione Password',
    "admin.enable": 'Abilitato',
    "admin.disable": "Disattivato",
    "admin.menu.config": "Configurazione",
    "admin.menu.epp":"EPP",
    "admin.menu.email":"Email",
    "admin.menu.polling":"Polling",
    "admin.menu.password":"Password EPP",


    // Configurazione POLLING:
    'admin.polling.enableEmail': 'Notifica Email',
    'admin.polling.enablePolling': 'Polling',
    'admin.polling.delay': 'Ritardo (in min.)',
    'admin.polling.maxMessages': 'Messaggi Max',
    'admin.pollig.emailFrom': 'Mittente',
    'admin.polling.emailTo': 'Destinatario',
    'admin.polling.addEmailTo': 'Aggiungi Destinatario',
    'admin.polling.update':'Aggiorna Configurazione Polling',

    'admin.polling.saveConf.info.message':'Configurazione Polling',
    'admin.polling.saveConf.info.description':'Salvataggio configuazione',

    'admin.polling.saveConf.error.message':'Configurazione Polling',
    'admin.polling.saveConf.error.description':'Si è verificato errore.',

    'admin.polling.saveConf.success.message':'Configurazione Polling',
    'admin.polling.saveConf.success.description':'Salvata con successo',

    'admin.email.update':'Aggiorna Configurazione Email',

    // Configurazione EMAIL:
    'admin.email.host': 'Host',
    'admin.email.host.placeholder': 'Host',
    'admin.email.port': 'Porta',
    'admin.email.port.placeholder': 'Porta',
    'admin.email.auth': 'Autenticazione',
    'admin.email.auth.username': 'Utente',
    'admin.email.auth.username.placeholder': 'Utente Autenticazione',
    'admin.email.auth.password': 'Password',
    'admin.email.auth.password.placeholder': 'Password Autenticazione',
    'admin.email.startTLS': 'Opzione STARTTLS',
    'admin.email.saveConf.info.message':'Configurazione Email',
    'admin.email.saveConf.info.description':'Salvataggio Configurazione',
    'admin.email.saveConf.error.message':'Configurazione Email',
    'admin.email.saveConf.error.description':'Si è verificato errore: {reason}',
    'admin.email.saveConf.success.message':'Configurazione Email',
    'admin.email.saveConf.success.description':'Salvata con successo',

    // Configurazione EPP:
    "admin.epp.serverURI":'Server EPP',
    "admin.epp.serverURI.placeholder":'Indirizzo Server EPP',
    "admin.epp.deletedServerURI":'Server Deleted EPP',
    'admin.epp.deletedServerURI.placeholder':'Indirizzo Server EPP Deleted',
    "admin.epp.username":"Utente",
    "admin.epp.username.placeholder":"Utente EPP",
    "admin.epp.password":"Password",
    "admin.epp.password.placeholder":"Password utente EPP",
    "admin.epp.password.confirm":"Conferma Password",
    "admin.epp.password.confirm.placeholder":"Conferma Password utente EPP",
    "passwordLength": 'Lunghezza della password errata: {len}. Deve compresa tra {min} e {max}',
    "admin.epp.dnssec":"DNSSec",
    "admin.epp.dnssec.enabled":"Abilitato",
    "admin.epp.dnssec.disabled":"Disattivato",
    "admin.epp.language":"Linguaggio",
    "admin.epp.update":"Aggiorna Configurazione EPP",
    'admin.epp.saveConf.info.message':'Configurazione EPP',
    'admin.epp.saveConf.info.description':'Salvataggio Configurazione',
    'admin.epp.saveConf.error.message':'Configurazione EPP',
    'admin.epp.saveConf.error.description':'Si è verificato errore: {reason}',
    'admin.epp.saveConf.success.message':'Configurazione EPP',
    'admin.epp.saveConf.success.description':'Salvata con successo',

    // Cambio password EPP
    "admin.epp.update.password": 'Modifica Password',
    "admin.epp.update.password.title": "Modifica Password EPP",

    // Domain UPDATE
    "domainUpdate.dns.error.message": "Errore nell'aggiornamento Dominio",
    "domainUpdate.dns.error.description.noAddNoRemove": "Non risultano modifiche da apportare al Dominio",
    "domainUpdate.dns.error.description.areTheSame":"Non è possibile eliminare ed aggiungere uno stesso nameserver",
    "domainUpdate.dsRecords.error.description.areTheSame": "Non è possibile eliminare ed aggiungere lo stesso record DS",
    "domainUpdate.dns.error.description.number":"Configurati {num} nameserver.\n Il numero deve essere compreso tra {min} e {max}",
    'domainUpdate.contacts.error.message':"Errore nell'aggiornamento dei Contatti" ,
    'domainUpdate.contacts.error.description.sameTech':"Contatto tecnico {techId} già presente nella configurazione originaria",
    'domainUpdate.contacts.error.description.sameAdmin': "Contatto Amministratore già presente nella configurazione originaria",
    'domainUpdate.contacts.error.description.noModification':"Nessuna modifica apportata alla configurazione originaria",
    'domainUpdate.contacts.error.description.adminMismatch':"Nessun aggiornamento nel contatto Amministratore",
    'domain.missingDays': 'Il dominio scadrà tra {days} giorni',
    'domain.expiredDays': 'Il dominio è scaduto da {days} giorni',
    'domain.signed': 'Dominio firmato DNSSEC',
    'domain.update.host.doneButton': 'Aggiorna Nameserver',
    'domain.update.contact.doneButton':'Aggiorna Contatti',
    cancel: 'Annulla',
    yes: 'Si',
    no: 'No',
    update: 'Aggiorna',
    profile: 'Profilo',

    'contactIdEmpty': 'Id contatto vuoto non ammesso',
    'contactIdTooShort': 'Id contatto troppo corto, specificare almeno 3 caratteri',
    'contactIdTooLong': 'Id contatto troppo lungo',

    'CHANGE_STATUS_MESSAGE': 'Modifica Stato Dominio',
    'CREDIT_MESSAGE':'Messaggio sul Credito',
    'SIMPLE_MESSAGE': 'Messaggio su Dominio',
    'PASSWORD_REMINDER_MESSAGE': 'Password in Scadenza',
    'TRANSFER_MESSAGE': 'Trasferimento Dominio',
    'WRONG_NAMESPACE_REMINDER_MESSAGE': 'Namespace Obsoleti',
    'REMAPPED_IDN_MESSAGE': 'Alterazione Dominio IDN',
    'DNS_ERROR_MESSAGE': 'Errore Validazione DNS Dominio',
    'DNS_WARNING_MESSAGE': 'Warning su Validazione DNS Dominio',
    'DELAYED_DEBIT_AND_REFUND_MESSAGE': 'Rimborso o Ritardato Addebito Dominio',
    'BROKEN_DELEGATION_MESSAGE': 'Problemi Delega Domini',
    'REFUND_RENEWS_FOR_BULKTRANSFER_MESSAGE': 'Rimborso Rinnovo Domini per BulkTransfer',
    'registrant' : 'Registrante',
    'registrantEdu': 'Registrante Edu',
    'registrantGov': 'Registrante Gov',
    'gov': 'Contatto Gov',
    'edu': 'Contatto Edu'
}
